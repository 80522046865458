import {
  LOADING,
  ERRORS,
  FOOTER_LOADING,
  SECONDARY_LOADING,
  CONTACT_US_SUBMIT,
  CONTACT_US_ERRORS,
} from "../types";
const initialState = {
  loading: false,
  footerLoading: false,
  secondaryLoading: false,
  errors: [],
  postLoading: false
};

const commonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING:
      if (payload)
        return {
          ...state,
          loading: payload,
          errors: [],
        };
      else return { ...state, loading: payload };
    case FOOTER_LOADING:
      return {
        ...state,
        footerLoading: payload,
      };
    case SECONDARY_LOADING:
      return {
        ...state,
        secondaryLoading: payload,
      };
    case ERRORS:
      return { ...state, errors: payload };

    case CONTACT_US_SUBMIT:
      return { ...state, ContactUsSubmit: payload.data };
    case CONTACT_US_ERRORS:
      return { ...state, errors: payload.data };
    default:
      return state;
  }
};
export default commonReducer;
