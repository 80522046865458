import {
  RESET_FINANCE_REJECTION_REASON,
  GET_FINANCE_ORDER_DATA,
  GET_FINANCE_OPTIONAL_REFUND,
  GET_EMKAN_DATA,
} from "../types";

const initialState = {
  orderId: false,
  formErrors: [],
  financeResponseData: false,
  orderData: [],
  updatedValues: {
    whatsapp_optin: 1,
  },
  banks: [],
  optionalRefund: {},
  emkanData: {},
};

const financeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_FINANCE_ORDER_DATA:
      return { ...state, formErrors: [], orderId: "", orderData: payload };

    case RESET_FINANCE_REJECTION_REASON:
      return {
        ...state,
        orderId: "",
        financeResponseData: false,
        formErrors: [],
        orderData: [],
      };

    case GET_FINANCE_OPTIONAL_REFUND:
      return { ...state, optionalRefund: payload };
    case GET_EMKAN_DATA:
      return { ...state, emkanData: payload };

    default:
      return state;
  }
};

export default financeReducer;
