import {
  SURVEY_DATA,
  SURVEY_ORDER_DATA,
  SET_SURVEY_CODE,
  GET_NEW_SERVEY_DATA,
  SET_SUBMIT_LOADING,
} from "../types";

const initialState = {
  orderData: "",
  surveyData: "",
  newSurveyData: [],
  submitLoading: false,
};

const SurveyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SURVEY_ORDER_DATA:
      return {
        ...state,
        orderData: payload,
        surveyData: "",
      };

    case SURVEY_DATA:
      return {
        ...state,
        surveyData: payload,
      };

    case SET_SURVEY_CODE:
      return {
        ...state,
        code: payload,
      };

    case GET_NEW_SERVEY_DATA:
      return {
        ...state,
        newSurveyData: payload,
      };

    case SET_SUBMIT_LOADING:
      return {
        ...state,
        submitLoading: payload,
      };

    default:
      return state;
  }
};

export default SurveyReducer;
