export const onPushDataLayer = (
  event = undefined,
  eventCategory = undefined,
  eventAction = undefined,
  eventLabel = undefined
) => {
  return PushDataLayer(event, eventCategory, eventAction, eventLabel);
};
export const onPushDataLayerNew = (
  event = undefined,
  event_category = undefined,
  event_action = undefined,
  event_label = undefined
) => {
  return PushDataLayerNew(event, event_category, event_action, event_label);
};

export const onPushDataLayerObject = (obj) => {
  return PushDataLayerObject(obj);
};
