import loadable from "@loadable/component";

/**
 * Asynchronously loads the Sentry client from `@sentry/react`. This allows for
 * dynamic importing of Sentry, optimizing initial load time by including Sentry
 * only when necessary.
 *
 * @param {object} [req=null] - Optional request object, which can be used for request-specific Sentry configurations or initializations.
 * @returns {Promise<typeof import('@sentry/react')>} A promise that resolves to the imported Sentry module.
 */
export const loadSentryClient = async (req = null) => {
    if (req?.Sentry) {
        return req.Sentry;
    }
    const loadSentry = loadable.lib(() => import('@sentry/react'));
    return new Promise((resolve, reject) => {
        loadSentry.load().then((SentryModule) => {
            resolve(SentryModule.default);
        }).catch((error) => {
            console.error("-> loadSentryClient error", error);
            reject(null);
        });
    });
};