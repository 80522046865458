import {
  SET_USER_DATA,
  SET_AUTH_DATA,
  AUTH_LOADING,
  LOGOUT_USER,
} from "../types";
const initialState = {
  loading: false,
  authData: [],
  loggedin: false,
  suspended: false,
  user: {},
  data: [],
  route: null,
  config: {
    makes: [],
  },
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_DATA:
      return {
        ...state,
        user: payload,
        loggedin: !!payload.id,
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: initialState.user,
        loggedin: initialState.loggedin,
      };
    case SET_AUTH_DATA:
      return { ...state, authData: payload };
    case AUTH_LOADING:
      return { ...state, loading: payload };
    default:
      return state;
  }
};
export default authReducer;
