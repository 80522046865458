import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
/**
 * Extends the Window interface to include the prevPath property.
 * This is necessary for TypeScript to recognize window.prevPath.
 */
/**
 * Custom hook to get the previous referral page.
 *
 * This hook retrieves the previous path stored in the global window object (window.prevPath)
 * and returns it. It does not modify or set any values.
 *
 * @returns {string} The pathname of the previous page or an empty string if not set.
 */
var useGetReferral = function useGetReferral() {
  if (__IS_SSR__) return '';
  return window.prevPath || '';
};
export default useGetReferral;
/**
 * Custom hook to set and maintain the referral URL.
 *
 * This hook listens for changes in the current location and updates the global
 * window.prevPath accordingly. It should be initialized at the top level of your
 * application (e.g., in client.js) to ensure the referral path is maintained globally.
 */
var useSetReferral = function useSetReferral() {
  var location = useLocation();
  useEffect(function () {
    // Update window.prevPath with the current location for future references
    setTimeout(function () {
      window.prevPath = location.pathname + location.search;
    }, 500);
  }, [location]);
};
export { useSetReferral };