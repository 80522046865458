import { useLang } from "@/common/Contexts/LanguageContext";
import { createInternalUrlWithParams } from "@/utils/common";
import { useNavigate } from "react-router-dom";
import { serverUrl } from "@globalConfig";
var useHelpers = function useHelpers() {
  var lang = useLang();
  var navigate = useNavigate();
  var lang2 = lang === "ar" ? "en" : "ar";
  var createUrl = function createUrl(url) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var hash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    if (url.startsWith('http')) {
      var newUrl = new URL(url);
      return newUrl.pathname + newUrl.search;
    }
    var uri = createInternalUrlWithParams(url, params, lang, hash);
    if (lang === 'en' && uri.startsWith('/en')) {
      uri = uri.replace('/en/en', '/en');
    }
    return uri;
  };
  var createUrlAbsolute = function createUrlAbsolute(url) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return serverUrl + createUrl(url, params);
  };
  var navigateTo = function navigateTo(url) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return navigate(createUrl(url, params));
  };
  var navigateToHard = function navigateToHard(url) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    window.location.href = createUrl(url, params);
  };
  var createUrl2 = function createUrl2(url) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return createInternalUrlWithParams(url, params, lang2);
  };
  return {
    createUrl: createUrl,
    createUrlAbsolute: createUrlAbsolute,
    createUrl2: createUrl2,
    navigateTo: navigateTo,
    navigateToHard: navigateToHard
  };
};
export default useHelpers;