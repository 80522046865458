import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

export const useFirstRenderHistoryLogic = () => {
  const location = useLocation();

  useEffect(() => {
    const handleNavigation = () => {
      window.USE_FIRST_RENDER = false;
    };

    // Initial render should set the window.USE_FIRST_RENDER
    handleNavigation();

    return () => {
      window.USE_FIRST_RENDER = false;
    };
  }, [location]);
};

const useFirstRender = () => {
  const defaultValue = __IS_SSR__ ? false : window.USE_FIRST_RENDER ?? true;
  const [isFirstRender, setIsFirstRender] = useState(defaultValue);

  useEffect(() => {
    const updateState = () => {
      setIsFirstRender(window.USE_FIRST_RENDER);
    };

    updateState();

    return () => {
      setIsFirstRender(false);
    };
  }, []);

  return isFirstRender;
};
export default useFirstRender;