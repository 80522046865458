import {
  SET_TRAKING_ERRORS_MESSAGE,
  SHOW_TRACK_ORDER,
  ORDER_CANCELED,
  GET_REFERRAL_DATA,
  GET_REFERRAL_LIST,
  GET_CAR_INFO,
  TRACKING_FORM,
  RESET_ORDER,
  RESET_CAR_INFO,
  SHOW_FORM,
} from "../types";

const initialState = {
  orderId: null,
  formErrorMessage: "",
  showForm: false,
  orderCanceled: false,
  orderData: {},
  canceledOrderData: {},
  referralData: {},
  referralList: [],
  referralListCurrentPage: 1,
  referralListPageCount: null,
  carInfo: {},
};

const trakingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TRAKING_ERRORS_MESSAGE:
      return {
        ...state,
        formErrorMessage: payload,
      };
    case TRACKING_FORM:
      return {
        ...state,
        showForm: payload,
      };
    case SHOW_TRACK_ORDER:
      return {
        ...state,
        showForm: false,
        orderData: payload,
        orderId: payload.id,
      };
    case RESET_ORDER:
      return {
        ...state,
        showForm: true,
        orderData: {},
        orderId: null,
      };
    case RESET_CAR_INFO:
      return {
        ...state,
        carInfo: payload,
      };
    case ORDER_CANCELED:
      return {
        ...state,
        showForm: false,
        orderCanceled: true,
        canceledOrderData: payload,
        orderId: payload.orderId,
      };
    case GET_REFERRAL_DATA:
      return {
        ...state,
        referralData: payload,
      };
    case GET_REFERRAL_LIST:
      return {
        ...state,
        referralList: payload.items,
        referralListCurrentPage: payload.current_page,
        referralListPageCount: payload.page_count,
      };
    case GET_CAR_INFO:
      return {
        ...state,
        carInfo: payload,
      };
    case SHOW_FORM:
      return {
        ...state,
        showForm: payload,
      };
    default:
      return state;
  }
};

export default trakingReducer;
