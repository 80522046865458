import stl from "./Loaderv2.module.css";

const Loader = ({ smallLoader = false, className = "" }) => {
  return (
    <div
      className={[
        stl.loadContainer,
        smallLoader ? stl.smallLoader : "",
        className ? className : "",
      ].join(" ")}
    >
      <span className={stl.loader}></span>
    </div>
  );
};

export default Loader;
