import {
  GET_SERVICE_CENTERS_DATA,
  GET_SERVICE_CENTERS_RESULTS,
} from "../types";

const initialState = {
  availableSCData: {},
  SCResults: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SERVICE_CENTERS_DATA:
      return { ...state, availableSCData: payload };
    case GET_SERVICE_CENTERS_RESULTS:
      return { ...state, SCResults: payload };

    default:
      return state;
  }
};
