import _slicedToArray from "/usr/app/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import _createForOfIteratorHelper from "/usr/app/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
export var isAutosOrYearsScreen = function isAutosOrYearsScreen(location) {
  return isAutosScreen(location) || isYearsScreen(location);
};
export var isAutosScreen = function isAutosScreen(location) {
  return location.pathname.includes("/autos");
};
export var isYearsScreen = function isYearsScreen(location) {
  return location.pathname.includes("/years");
};
export var isFiltersScreen = function isFiltersScreen(location) {
  return location.pathname.includes("/filters") || location.pathname.includes("/deal");
};
export var getBrowseLink = function getBrowseLink(location) {
  var dealId = extractDealId(new URLSearchParams(location.search), location);
  var dealParam = dealId ? "?deal=".concat(dealId) : "";
  return decodeURI(location.pathname).replace(/^(\/en)/, "").slugify() + dealParam;
};
export var extractDealId = function extractDealId(searchParams, location) {
  var _location$pathname$ma;
  var dId = searchParams.get("deal_id") || searchParams.get("deal");
  return Number(dId || ((_location$pathname$ma = location.pathname.match(/\/deal\/(\d+)/)) === null || _location$pathname$ma === void 0 ? void 0 : _location$pathname$ma[1]));
};
export var getPageSize = function getPageSize(device) {
  switch (device) {
    case "mobile":
      return 4;
    case "tablet":
      return 6;
    default:
      return 12;
  }
};
export var buildSearchParams = function buildSearchParams(filters) {
  var searchParams = [];
  Object.entries(filters).forEach(function (filter, i) {
    var filterName = filter[0];
    var filterValue = filter[1];
    if (!filterValue && filterValue !== 0 || Array.isArray(filterValue) && !filterValue.length) return;
    searchParams.push("".concat(filterName, "=").concat(Array.isArray(filterValue) ? filterValue.join(",") : filterValue));
  });
  return searchParams.join("&");
};
export var buildParamsObject = function buildParamsObject(params, location) {
  var paramsObject = {};
  var _iterator = _createForOfIteratorHelper(params.entries()),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _step$value = _slicedToArray(_step.value, 2),
        key = _step$value[0],
        value = _step$value[1];
      if (key === "deal" && value) {
        paramsObject["deal_id"] = value;
        continue;
      }
      paramsObject[key] = value;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  if (!paramsObject['deal_id']) {
    var path = decodeURI(location.pathname);
    var _ref = path.match(/\/deal\/(\d+)/) || [],
      _ref2 = _slicedToArray(_ref, 2),
      _ = _ref2[0],
      dealPathFromPath = _ref2[1];
    dealPathFromPath && (paramsObject['deal_id'] = dealPathFromPath);
  }
  return paramsObject;
};
// TODO: check if needed
export var getSubMenuHeight = function getSubMenuHeight() {
  var _document, _document3, _document3$getElement, _document4, _document4$getElement;
  var initHeight = 0;
  if ((_document = document) !== null && _document !== void 0 && _document.getElementById("AsideHeight2")) {
    var _document2;
    initHeight = (_document2 = document) === null || _document2 === void 0 ? void 0 : _document2.getElementById("AsideHeight2").offsetHeight;
  }
  window.innerWidth < 1025 ? document.documentElement.style.setProperty("--subMenuHeight", 50 + ((_document3 = document) === null || _document3 === void 0 ? void 0 : (_document3$getElement = _document3.getElementById("AsideHeightOne")) === null || _document3$getElement === void 0 ? void 0 : _document3$getElement.offsetHeight) + "px") : document.documentElement.style.setProperty("--subMenuHeight", initHeight + ((_document4 = document) === null || _document4 === void 0 ? void 0 : (_document4$getElement = _document4.getElementById("AsideHeightOne")) === null || _document4$getElement === void 0 ? void 0 : _document4$getElement.offsetHeight) + "px");
};
export function handleFilter(filters) {
  delete filters.sort;
  if (filters !== null && filters !== void 0 && filters.is_new && typeof (filters === null || filters === void 0 ? void 0 : filters.is_new) === "string") {
    filters.is_new = filters.is_new === "true" || filters.is_new === "1" ? 1 : 0;
  }
  if (filters !== null && filters !== void 0 && filters.odometer_to && typeof (filters === null || filters === void 0 ? void 0 : filters.odometer_to) === "string") {
    filters.odometer_to = filters.odometer_to.replaceAll(",", "");
  }
  if (filters !== null && filters !== void 0 && filters.odometer_from && typeof (filters === null || filters === void 0 ? void 0 : filters.odometer_from) === "string") {
    filters.odometer_from = filters.odometer_from.replaceAll(",", "");
  }
  filters.text = filters.text || "";
  return filters;
}
export var getLoaderBoxesCount = function getLoaderBoxesCount(device) {
  if (device === 'mobile') return 6;
  if (device === 'tablet') return 8;
  return 30;
};
export var getWhenToShowUSPsRow__perDevice = function getWhenToShowUSPsRow__perDevice(device) {
  // fixme: this should be per device
  if (device === 'mobile') return 4;
  if (device === 'tablet') return 4;
  if (device === 'desktop_small') return 3;
  return 4;
};
export var getSearchPageTitle = function getSearchPageTitle(searchResults) {
  var _searchResults$metaDa, _searchResults$metaDa2;
  if (searchResults !== null && searchResults !== void 0 && searchResults.title) {
    return searchResults.title;
  }
  return (searchResults === null || searchResults === void 0 ? void 0 : (_searchResults$metaDa = searchResults.metaData) === null || _searchResults$metaDa === void 0 ? void 0 : (_searchResults$metaDa2 = _searchResults$metaDa.seoData) === null || _searchResults$metaDa2 === void 0 ? void 0 : _searchResults$metaDa2.title) || "";
};
export var getSearchPageH1Title = function getSearchPageH1Title(searchResults, searchTerm, t) {
  var _searchResults$metaDa3, _searchResults$metaDa4, _searchResults$metaDa5;
  if ((searchResults === null || searchResults === void 0 ? void 0 : searchResults.selectedOptions.length) === 0 && searchTerm) {
    return "".concat(t("نتائج البحث عن", {
      ns: "web_search"
    }), " \"").concat(searchTerm, "\"");
  }
  var count = searchResults === null || searchResults === void 0 ? void 0 : (_searchResults$metaDa3 = searchResults.metaData) === null || _searchResults$metaDa3 === void 0 ? void 0 : _searchResults$metaDa3.productsCount;
  var title = ((_searchResults$metaDa4 = searchResults.metaData) === null || _searchResults$metaDa4 === void 0 ? void 0 : (_searchResults$metaDa5 = _searchResults$metaDa4.seoData) === null || _searchResults$metaDa5 === void 0 ? void 0 : _searchResults$metaDa5.h1) || null;
  if (!count) return title;
  return title ? "".concat(title, " - (").concat(count, ")") : null;
};