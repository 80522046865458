import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const middleware = [thunk];

// export const RootState = any;

export default function makeStore(initState = {}) {
  return createStore(
    rootReducer,
    initState,
    compose(applyMiddleware(...middleware))
    // compose(
    //   applyMiddleware(...middleware),
    //  typeof window !== undefined && window.__REDUX_DEVTOOLS_EXTENSION__ &&
    //     window.__REDUX_DEVTOOLS_EXTENSION__()
    // )
  );
};
