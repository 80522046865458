import {
  GET_TRACKING_CHECKOUT_ORDERS,
  RESET_TRACKING_CHECKOUT_ORDERS,
  GET_ALL_OFFERED_DOC,
} from "../types";

const initialState = {
  orders: [],
  allOfferedDoc: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TRACKING_CHECKOUT_ORDERS:
      return { ...state, orders: payload };
    case RESET_TRACKING_CHECKOUT_ORDERS:
      return { ...state, orders: payload };
    case GET_ALL_OFFERED_DOC:
      return { ...state, allOfferedDoc: payload };
    default:
      return state;
  }
};
