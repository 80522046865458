import _objectSpread from "/usr/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useEffect, useState } from 'react';
function useInitDataLayerMeta(_ref) {
  var isWebView = _ref.isWebView,
    isIos = _ref.isIos;
  var _useState = useState({
      platform: 'web'
    }),
    config = _useState[0],
    setConfig = _useState[1];
  useEffect(function () {
    var platform = isWebView ? isIos ? "ios" : "android" : "web";
    setConfig(_objectSpread(_objectSpread({}, config), {}, {
      platform: platform
    }));
  }, []);
  useEffect(function () {
    window.dataLayerMeta = config;
  }, [config]);
}
export default useInitDataLayerMeta;