import { TRACKING_FINANCE_DETAILS, TRACKING_FINANCE_ORDERS } from "../types";

const initialState = {
  orders: {},
  details: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TRACKING_FINANCE_ORDERS:
      return { ...state, orders: payload };
    case TRACKING_FINANCE_DETAILS:
      return { ...state, details: payload };

    default:
      return state;
  }
};
