import  {createAxiosInstance} from "@/common/axiosInstance";

const BASE_PATH = 'erpnext_v1';

const erpFetcher = () => {

  const axiosInstance2 = createAxiosInstance(null, BASE_PATH, true);

  return axiosInstance2;
};

export default erpFetcher;
