export const ERRORS = "ERRORS";
export const LOADING = "LOADING";
export const FOOTER_LOADING = "FOOTER_LOADING";
export const SECONDARY_LOADING = "SECONDARY_LOADING";
export const GET_SEARCH_FILTERS = "GET_SEARCH_FILTERS";
export const SET_USER_DATA = "SET_USER_DATA";
export const POST_OFFLINE_ORDER = "POST_OFFLINE_ORDER";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const CREATE_ORDER = "CREATE_ORDER";
export const REFERRAL_ORDER = "REFERRAL_ORDER";
export const UPDATEFIELDS = "UPDATEFIELDS";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";
export const CONTACT_US_SUBMIT = "CONTACT_US_SUBMIT";
export const CONTACT_US_ERRORS = "CONTACT_US_ERRORS";
export const RESET_CHECKOUT_FIELDS = "RESET_CHECKOUT_FIELDS";
export const SET_TRAKING_ERRORS_MESSAGE = "SET_TRAKING_ERRORS_MESSAGE";
export const SHOW_TRACK_ORDER = "SHOW_TRACK_ORDER";
export const ORDER_CANCELED = "ORDER_CANCELED";
export const GET_REFERRAL_DATA = "GET_REFERRAL_DATA";
export const GET_REFERRAL_LIST = "GET_REFERRAL_LIST";
export const GET_CAR_INFO = "GET_CAR_INFO";
export const TRACKING_FORM = "TRACKING_FORM";
export const RESET_ORDER = "RESET_ORDER";
export const RESET_CAR_INFO = "RESET_CAR_INFO";
export const RESET_FINANCE_REJECTION_REASON = "RESET_FINANCE_REJECTION_REASON";
export const GET_FINANCE_ORDER_DATA = "GET_FINANCE_ORDER_DATA";
export const GET_OFFLINE_ORDER_DETAILS = "GET_OFFLINE_ORDER_DETAILS";
export const SURVEY_DATA = "SURVEY_DATA";
export const SURVEY_ORDER_DATA = "SURVEY_ORDER_DATA";
export const SET_SURVEY_CODE = "SET_SURVEY_CODE";
export const GET_REMAINING_ORDER_DETAILS = "GET_REMAINING_ORDER_DETAILS";
export const GET_PAYLINK_ORDER_DETAILS = "GET_PAYLINK_ORDER_DETAILS";
export const GET_FINANCE_REMAINING_ORDER_DETAILS =
  "GET_FINANCE_REMAINING_ORDER_DETAILS";
export const SADAD_INFORMATION = "SADAD_INFORMATION";
export const SADAD_ERROR = "SADAD_ERROR";
export const GET_NEW_SERVEY_DATA = "GET_NEW_SERVEY_DATA";
export const SET_SUBMIT_LOADING = "SET_SUBMIT_LOADING";
export const GET_NEW_POST_VIEW_DATA = "GET_NEW_POST_VIEW_DATA";
export const SHOW_FORM = "SHOW_FORM";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const SET_AUTH_DATA = "SET_AUTH_DATA";
export const GET_USER_FAVORITE = "GET_USER_FAVORITE";
export const AUTH_LOADING = "AUTH_LOADING";
export const GET_TRACKING_CHECKOUT_ORDERS = "GET_TRACKING_CHECKOUT_ORDERS";
export const TRACKING_FINANCE_ORDERS = "TRACKING_FINANCE_ORDERS";
export const TRACKING_FINANCE_DETAILS = "TRACKING_FINANCE_DETAILS";
export const RESET_TRACKING_CHECKOUT_ORDERS = "RESET_TRACKING_CHECKOUT_ORDERS";
export const GET_ALL_OFFERED_DOC = "GET_ALL_OFFERED_DOC";
export const GET_TRACKING_STEP_DETAILS = "GET_TRACKING_STEP_DETAILS";
export const RESET_TRACKING_STEP_DETAILS = "RESET_TRACKING_STEP_DETAILS";

export const LOGOUT_USER = "LOGOUT_USER";

/* search  */
export const GET_SERVICE_CENTERS_DATA = "GET_SERVICE_CENTERS_DATA";
export const GET_SERVICE_CENTERS_RESULTS = "GET_SERVICE_CENTERS_RESULTS";
//TODO: remove this action with all dependencies
//@Suhaib
export const GET_EMKAN_DATA = "GET_EMKAN_DATA";
export const GET_FINANCE_OPTIONAL_REFUND = "GET_FINANCE_OPTIONAL_REFUND";
