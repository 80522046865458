import {checkFeatureFlag} from "@/common/config/websiteConfig";

export const serverUrl = process.env.RAZZLE_SERVER_URL;
export const apiUrl = process.env.RAZZLE_API_ENDPOINT;
export const CLIENT_SECRET = process.env.RAZZLE_CLIENT_SECRET;
export const SELECTED_ENV = process.env.RAZZLE_ENV;
export const PHONE_CONTACT_NUMBER =
    process.env.RAZZLE_PHONE_CONTACT_NUMBER || "+966920005379";
export const WHATSAPP_CONTACT_NUMBER =
    process.env.RAZZLE_WHATSAPP_CONTACT_NUMBER || PHONE_CONTACT_NUMBER;
export const CDN_DOMAIN = process.env.RAZZLE_CDN_LINK;
export const CDN_LINK =
    process.env.NODE_ENV !== "production" ? "" : `${CDN_DOMAIN}/${SELECTED_ENV}`;
export const CHECKOUT_VISA_PUBLIC_KEY =
    process.env.RAZZLE_CHECKOUT_PUBLIC_KEY_VISA;
export const CHECKOUT_MADA_PUBLIC_KEY =
    process.env.RAZZLE_CHECKOUT_PUBLIC_KEY_MADA;
export const ARBNOON_AMOUNT = 150;
export const FINANCE_APP_AMOUNT = 10;
export const SADAD_MAX_AMOUNT = 250000;
export const SENTRY_DSN = process.env.RAZZLE_SENTRY_SERVER_DSN;
export const SENTRY_CLIENT_DSN = process.env.RAZZLE_SENTRY_CLIENT_DSN;
export const BUILD_VERSION = process.env.RAZZLE_BUILD_VERSION;

export const CRYPTO_JS_SECRET_KEY =
    process.env.RAZZLE_FRONT_END_CRYPTO_JS_SECRET_KEY;
export const SENTRY_TRACE_SAMPLE_RATE = Number(
    process.env.RAZZLE_SENTRY_TRACE_SAMPLE_RATE || "0.05"
);
export const SIGN_UP_A_B_TESTING = process.env.RAZZLE_SIGN_UP_A_B_TESTING;
export const I18N_CDN =
    process.env.RAZZLE_I18N_CDN ||
    `https://testcdn-frontend-r2.syarah.com/{env}/translations/{lang}/{namespace}.json`;
export const IS_MOZN = process.env.RAZZLE_IS_MOZN === "true" || false;
export const GROWTHBOOK_CLIENT_KEY = process.env.RAZZLE_GROWTHBOOK_CLIENT_KEY;

export const HEADLESS_CMS_API_BASE_PATH =
    process.env.RAZZLE_HEADLESS_CMS_API_BASE;
export const HEADLESS_CMS_IMAGE_URL =
    process.env.RAZZLE_HEADLESS_CMS_IMAGE_URL.replace("{CDN_LINK}", CDN_DOMAIN);
export const HEADLESS_CMS_AUTH_TOKEN =
    process.env.RAZZLE_HEADLESS_CMS_AUTH_TOKEN;
export const FF_DEBUG_TRANSLATION =
    process.env.RAZZLE_FF_DEBUG_TRANSLATION === "true";
export const SHOW_DEBUG_INFO = SELECTED_ENV==='local' || SELECTED_ENV==='qa';

export const LIST_IMAGES_AS_SLIDER =
    process.env.RAZZLE_LIST_IMAGES_AS_SLIDER === "true" || false;
export const ENABLE_APPOINTMENT_NAVIGATION = checkFeatureFlag("FF_ENABLE_APPOINTMENT_NAVIGATION");
export const LAST_FACET_YEAR = Number(
    process.env.RAZZLE_LAST_FACET_YEAR || "2025"
);
export const FACETS_YEARS_COUNT = Number(
    process.env.RAZZLE_FACETS_YEARS_COUNT || "10"
);
export const NOTIFICATIONS_INTEREST_CONFIG = process.env.RAZZLE_NOTIFICATIONS_INTEREST_CONFIG || "3times|3days"

export const SYARAH_V2_JWT_SECRET = process.env.RAZZLE_SYARAH_V2_JWT_SECRET || "34HoxEMmDBNRdB0hltpZuDFzf0fc3RNs";
export const JWT_EXPIRE_TIME_IN_MINUTES = Number(process.env.RAZZLE_JWT_EXPIRE_TIME_IN_MINUTES || "5");

export const posthog_key = process.env.RAZZLE_APP_PUBLIC_POSTHOG_KEY || 'phc_KZu5BTvueGlOXMJjfISkftmme1qO4bgoNfPCj4rKlHm';
export const posthog_apihost = process.env.RAZZLE_APP_PUBLIC_POSTHOG_HOST || 'https://posthog-dev.syarah.com';
export const disable_posthog = process.env.RAZZLE_APP_DISABLE_POSTHOG || false;
export const GTM_DISABLED = process.env.RAZZLE_GTM_DISABLED === "true";
