import { combineReducers } from "redux";
import authReducer from "./authReducer";
import commonReducer from "./commonReducer";
import searchReducer from "./searchReducer";
import postReducer from "./postReducer";
import userReducer from "./userReducer";
import checkoutReducer from "./checkoutReducer";
import trackingReducer from "./trackingReducer";
import financeReducer from "./financeReducer";
import surveyReducer from "./surveyReducer";
import trackingFinanceReducer from "./trackingFinanceReducer";
import trackingCheckoutReducer from "./trackingCheckoutReducer";
import serviceCentersReducer from "./serviceCentersReducer";
import trackingStepReducer from "./trackingStepReducer";
var reducers = combineReducers({
  auth: authReducer,
  common: commonReducer,
  search: searchReducer,
  post: postReducer,
  user: userReducer,
  checkout: checkoutReducer,
  tracking: trackingReducer,
  trackingCheckout: trackingCheckoutReducer,
  trackingFinance: trackingFinanceReducer,
  trackingStep: trackingStepReducer,
  finance: financeReducer,
  survey: surveyReducer,
  serviceCenters: serviceCentersReducer
});
export default reducers;