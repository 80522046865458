import {
  GET_NEW_POST_VIEW_DATA,
} from "../types";
const initialState = {
  newPostData: {},
  similarPosts: null,
  metaTags: [],
};
const postReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_NEW_POST_VIEW_DATA:
      return {
        ...state,
        newPostData: { ...state.newPostData, ...payload.postDetails },
        similarPosts: payload.similarPosts,
        metaTags: payload.postDetails.meta,
      };

    default:
      return state;
  }
};
export default postReducer;