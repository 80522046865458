import {useEffect, useState} from 'react';

function checkIsIos() {
    const userAgent = window.navigator.userAgent;
    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
}

function useIsIOS() {
    const [isIOS, setIsIOS] = useState(__IS_CSR__ && checkIsIos());

    useEffect(() => {
        setIsIOS(checkIsIos());
    }, []);

    return isIOS;
}

export default useIsIOS;