import { useEffect, useState } from "react";
var DEFAULT_TIMEOUT = 6000;
var useDelayRender = function useDelayRender() {
  var timeout = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_TIMEOUT;
  var _useState = useState(false),
    isDelayRender = _useState[0],
    setIsDelayRender = _useState[1];
  useEffect(function () {
    setTimeout(function () {
      setIsDelayRender(true);
    }, timeout);
  }, []);
  return isDelayRender;
};
export default useDelayRender;