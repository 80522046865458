import _extends from "/usr/app/node_modules/@babel/runtime/helpers/esm/extends.js";
import React from "react";
var __jsx = React.createElement;
import { useGetTranslations } from "@/common/Contexts/LanguageContext";
import namespaces from "@/i18n/namespaces";
import { FF_DEBUG_TRANSLATION } from "@globalConfig";
var translationFuncCreator = function translationFuncCreator(translations_data) {
  return function (key) {
    var _translations_data$pa, _translations_data$pa2;
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var lbl = (_translations_data$pa = translations_data === null || translations_data === void 0 ? void 0 : (_translations_data$pa2 = translations_data[params.ns]) === null || _translations_data$pa2 === void 0 ? void 0 : _translations_data$pa2[key]) !== null && _translations_data$pa !== void 0 ? _translations_data$pa : null;
    if (lbl === null) {
      namespaces.forEach(function (ns) {
        if (lbl === null) {
          var _translations_data$ns, _translations_data$ns2;
          lbl = (_translations_data$ns = translations_data === null || translations_data === void 0 ? void 0 : (_translations_data$ns2 = translations_data[ns]) === null || _translations_data$ns2 === void 0 ? void 0 : _translations_data$ns2[key]) !== null && _translations_data$ns !== void 0 ? _translations_data$ns : null;
        }
      });
    }
    if (lbl === null) {
      lbl = key;
    }
    lbl = typeof lbl === "string" ? lbl : JSON.stringify(key);
    var finalText = (lbl || "").replace(/{{(.*?)}}/g, function (match, p1) {
      return params[p1.trim()] || match;
    });
    if (__IS_CSR__ && window.location.href.includes("/en")) {
      var flag = FF_DEBUG_TRANSLATION || document.cookie.includes("debug_tr") || window.location.href.includes("debug_tr");
      if (flag) {
        return "**".concat(finalText, "**");
      }
    }
    return finalText;
  };
};
// handel old Class React Components using HOC
export var withTranslation = function withTranslation(groups) {
  return function (WrappedComponent) {
    // This is now a functional component
    return function TranslatedComponent(props) {
      var translation_data = useGetTranslations();
      // Return the WrappedComponent (which is a class component) with the translation function
      return __jsx(WrappedComponent, _extends({}, props, {
        t: translationFuncCreator(translation_data)
      }));
    };
  };
};
// Modern React Hooks
var useTranslation = function useTranslation() {
  var ns = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ["web_common"];
  var translations = useGetTranslations();
  return {
    t: translationFuncCreator(translations)
  };
};
export default useTranslation;