import {
  GET_TRACKING_STEP_DETAILS,
  RESET_TRACKING_STEP_DETAILS,
} from "../types";

const initialState = {
  trackingStepDetails: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TRACKING_STEP_DETAILS:
      return { ...state, trackingStepDetails: payload };
    case RESET_TRACKING_STEP_DETAILS:
      return { ...state, trackingStepDetails: payload };
    default:
      return state;
  }
};
