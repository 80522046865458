if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function(search, replacement) {
        // Ensure the search is a string (to use split)
        var target = this;
        if (typeof search === 'string') {
            // If search is a string, use split and join
            return target.split(search).join(replacement);
        } else {
            // If search is a regex, use replace with a global regex
            var globalRegex = new RegExp(search.source, 'g' + (search.ignoreCase ? 'i' : '') + (search.multiline ? 'm' : ''));
            return target.replace(globalRegex, replacement);
        }
    };
}

String.prototype.slugify = function() {
    return this.toString()
        .toLowerCase() // Trim leading and trailing spaces
        .trim() // Trim leading and trailing spaces
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/\-\-+/g, '-'); // Replace multiple - with single -
};
