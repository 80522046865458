import React from "react";
var __jsx = React.createElement;
import { useEffect, useState } from "react";
import SharedPopUp from "@componentsShared/BottomSheetPopup__OLD/SharedPopUp";
import stl from "./ConfirmPopup.module.css";
export var CONFIRM_POPUP_YES_EVENT = "confirm_popup_yes_event";
export var SHOW_POPUP_YES_EVENT = "show_popup_yes_event";
var configs = {
  title: "",
  message: "",
  yesButton: "",
  noButton: ""
};
export var configureConfirmPopup = function configureConfirmPopup(props) {
  if (props === null) {
    configs.title = "";
    configs.message = "";
    configs.yesButton = "";
    configs.noButton = "";
    return;
  }
  Object.assign(configs, props);
};
var ConfirmPopup = function ConfirmPopup() {
  var _useState = useState(false),
    showPopUp = _useState[0],
    setShowPopUp = _useState[1];
  var _useState2 = useState(""),
    id = _useState2[0],
    setID = _useState2[1];
  var yesClicked = function yesClicked() {
    setShowPopUp(false);
    window.dispatchEvent(new CustomEvent(CONFIRM_POPUP_YES_EVENT + id));
  };
  var noClicked = function noClicked() {
    setShowPopUp(false);
    document.body.style.overflow = "";
  };
  var showPopup = function showPopup(event) {
    if (!event.detail.id) {
      throw new Error("Event ID is required");
    }
    setID(event.detail.id);
    // trigger custom event
    setShowPopUp(true);
  };
  useEffect(function () {
    window.addEventListener(SHOW_POPUP_YES_EVENT, showPopup);
    return function () {
      window.removeEventListener(SHOW_POPUP_YES_EVENT, showPopup);
    };
  }, []);
  return __jsx(SharedPopUp, {
    show: showPopUp,
    onClose: function onClose() {
      setShowPopUp(false);
    },
    maxWidth: "563px"
  }, __jsx("div", {
    className: stl.popup
  }, __jsx("i", null), __jsx("strong", null, " ", configs.title), __jsx("p", null, " ", configs.message), __jsx("button", {
    onClick: noClicked,
    className: "blueBtn"
  }, " ", configs.noButton), __jsx("button", {
    onClick: yesClicked,
    className: "whtBtn"
  }, " ", configs.yesButton)));
};
export default ConfirmPopup;