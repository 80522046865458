var SELL_TRADE_DEFAULT_DATA = function SELL_TRADE_DEFAULT_DATA(userData, t) {
  return {
    steps: [{
      stepName: t('معلومات سيارتك', {
        ns: 'web_sell_trade'
      }),
      active: true,
      done: false,
      stepNumber: 1
    }, {
      stepName: t('السعر التقديري', {
        ns: 'web_sell_trade'
      }),
      active: false,
      done: false,
      stepNumber: 2
    }, {
      stepName: t('حجز موعد الفحص', {
        ns: 'web_sell_trade'
      }),
      active: false,
      done: false,
      stepNumber: 3
    }],
    progressPercentage: 0,
    area_approved_for_inspection: false,
    Sell_car_form1: {
      car_make_id: null,
      car_model_id: null,
      car_trim_id: null,
      year: null,
      odometer_range_id: null,
      car_condition_id: null,
      color_id: null,
      ownership_type: null
    },
    Sell_car_form2: {
      seller_name: (userData === null || userData === void 0 ? void 0 : userData.full_name) || null,
      seller_mobile: (userData === null || userData === void 0 ? void 0 : userData.phone_number) || null,
      send_whatsApp_notification: '1',
      city_id: null,
      city_Name: null,
      acceptTerms: null
    },
    erpResData: {
      request_id: null,
      minPrice: null,
      maxPrice: null
    },
    userSelection: {
      asking_price: null,
      inspection_location_type: null,
      inspection_location_name: null,
      area_id: null,
      location_freetext: null,
      note: null,
      datestamp: null,
      from_timestamp: null,
      to_timestamp: null
    }
  };
};
export default SELL_TRADE_DEFAULT_DATA;