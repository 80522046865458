import React, { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { loadableReady } from "@loadable/component";
import ReactDOM from "react-dom/client";
import App from "./App";
import extractedRoutes from "./routes/routes";
import makeStore from "./redux/store";
import "./styles/fontStyle.css";
import "../src/_FIX_ME_STYLES/home.css";
import Loader from "./components/sharedComponents/Loader";
import "./Polyfills";
import { getUserFromLocalStorage } from "./utils/auth";
import { external_first_interaction } from "@hooks/useIsFirstInteraction";
import { PostHogProvider} from 'posthog-js/react'
import {disable_posthog, posthog_apihost, posthog_key} from "@globalConfig";

let initState = window.INITIAL_STATE;
let userData = getUserFromLocalStorage();
initState = {
  ...initState,
  user: { ...initState?.user, userData },
  auth: {
    ...initState?.user,
    user: userData,
    loggedin: !!userData,
  },
};
const store = makeStore(initState);
const routes = extractedRoutes();
const container = ReactDOM.createRoot(document.getElementById("root"));
const posthogOptions = {
  api_host: posthog_apihost,
  person_profiles: 'always',
  enable_recording_console_log : true,
  session_recording: {
    maskAllInputs: true,
    maskInputFn: (text, element) => {
      /** @type {HTMLElement} */
      if (!element?.classList.contains('mysr-form-input')) {
        return text
      }
      return '*'.repeat(text.length)
    },
  },
  maskInputOptions: {
    password: true,
  }
}
const AppContainer = () => {
  useEffect(() => {
    external_first_interaction();
  }, []);
  return (
    <Suspense fallback={<Loader />}>
      <Provider store={store} >
        <BrowserRouter>
          {!disable_posthog && <PostHogProvider
              apiKey={posthog_key}
              options={posthogOptions}
          >
            <App routes={routes} store={store}/>
          </PostHogProvider>}
          {disable_posthog && <App routes={routes} store={store}/>}
        </BrowserRouter>
      </Provider>
    </Suspense>
  );
};


loadableReady(() => {
  container.render(<AppContainer />);
});

if (module.hot) {
  module.hot.accept();
}
