import _objectSpread from "/usr/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
// Add user data to browser local storage
import cookieClient from "react-cookies";
import { parseJsonSafely } from "./GlobalJavascriptFunction";
export var setUserToLocalStorage = function setUserToLocalStorage(userData) {
  if (userData === null) {
    localStorage.removeItem("user");
    return;
  }
  userData.opt_whatsapp_interest = Number(userData.opt_whatsapp_interest) || 0;
  localStorage.setItem("user", JSON.stringify(_objectSpread(_objectSpread({}, userData), {}, {
    expire: Date.now()
  })));
};
// Get user data from browser local storage
export var getUserFromLocalStorage = function getUserFromLocalStorage() {
  if (__IS_SSR__) {
    return null;
  }
  var userData = parseJsonSafely(localStorage.getItem("user"));
  if (userData) return userData;
};
// Get user data from browser local storage
export var isFavoritePost = function isFavoritePost(postId) {
  if (typeof window !== "undefined") {
    var userData = JSON.parse(localStorage.getItem("user"));
    if (userData && userData.favorites) {
      return userData.favorites.includes(+postId);
    }
  }
  return false;
};
// Get user data from browser local storage
export var AddRemoveFromFavorites = function AddRemoveFromFavorites(postId) {
  var addOperation = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var userData = JSON.parse(localStorage.getItem("user"));
  if (userData.favorites) {
    if (addOperation) userData.favorites.push(postId);else {
      var index = userData.favorites.indexOf(postId);
      if (index > -1) {
        // only splice array when item is found
        userData.favorites.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
  }
  setUserToLocalStorage(userData);
};
export function setAccessToken(token) {
  cookieClient.save("accessToken", token, {
    path: "/",
    maxAge: 3600 * 24 * 365 * 10,
    sameSite: true
  });
}
export function setUserId(userId) {
  cookieClient.save("userId", userId, {
    path: "/",
    maxAge: 3600 * 24 * 365 * 10,
    sameSite: true
  });
}
export function logout() {
  cookieClient.remove("user");
  cookieClient.remove("accessToken", {
    path: "/"
  });
  cookieClient.remove("userId", {
    path: "/"
  });
  setUserToLocalStorage(null);
}